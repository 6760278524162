<template>
  <b-container
    fluid
    class="pl-4 pt-3 pb-4 pr-4 shadow-bg"
  >
    <div class="legend-title"><strong>
      {{ `${range} = ${percentage}` }}
    </strong></div>
    <img
      v-for="(n,index) in rating"
      :key="index"
      src="../assets/star.svg"
    >
  </b-container>
</template>

<script>
export default {
  name: 'LegendItem',
  props: {
    range: {
      type: String,
      default: ''
    },
    percentage: {
      type: String,
      default: ''
    },
    rating: {
      type: Number,
      default: 0
    },
  },
  data: ()=> {
    return {

    }
  }
}
</script>
