
<template>
  <div class="Home">
    <b-container
      fluid
      class="bg-cover"
    >
      <b-row class="pb-0">
        <b-col> <img
          src="../assets/grade-logo.png"
          class="logo"
        > </b-col>

      </b-row>
      <b-row class="pl-5 pt-0 mt-2 pb-0 mb-0">
        <b-col
          cols="*"
          class="p-4"
        > <div class="header p-3 font-weight-bold">
          Do you have the right <br>data for your crops?
        </div> </b-col>

      </b-row>
      <b-row
        v-if="!isStartGrading"
        class="pl-5 pt-0 mt-3"
      >
        <b-col
          cols="*"
          class="p-4"
        > <b-button
          variant="primary"
          class="btn-blue"
          @click="isStartGrading = !isStartGrading"
        >Start grading <div class="button-ico"><svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="20"
          fill="currentColor"
          class="bi bi-chevron-right"
          viewBox="0 0 16 16"
          style="transform:scale(0.75)"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
          />
        </svg></div></b-button></b-col>

      </b-row>
      <b-row
        v-if="isStartGrading"
        class="pl-5 pt-0 mt-3"
      >
        <b-col
          sm="12"
          md="2"
          xl="2"

          class="pl-4 pt-2"
        > <b-button
          variant="primary"
          class="btn-blue"
          @click="type = 'primary'"
        >Primary<div class="button-ico"><svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="20"
          fill="currentColor"
          class="bi bi-chevron-right"
          viewBox="0 0 16 16"
          style="transform:scale(0.75)"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
          />
        </svg></div></b-button></b-col>

        <b-col
          sm="12"
          md="2"
          xl="2"

          class="pl-4 pt-2"
        > <b-button
          variant="primary"
          class="btn-blue"
          @click="type = 'secondary'"
        >Secondary<div class="button-ico"><svg
          xmlns="http://www.w3.org/2000/svg"
          width="10"
          height="20"
          fill="currentColor"
          class="bi bi-chevron-right"
          viewBox="0 0 16 16"
          style="transform:scale(0.75)"
        >
          <path
            fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
          />
        </svg></div></b-button></b-col>

      </b-row>
    </b-container>
    <b-container
      fluid
      class="text-center"
    >
      <b-row
        v-if="type === 'primary' || type === 'secondary'"
        class="pt-5 pb-0 legend-bar"
      >
        <b-col
          sm="4"
          md="4"
          xl="2"
        >
          <LegendItem
            range="0"
            percentage="100%"
            :rating="5"
          />
        </b-col>
        <b-col
          sm="4"
          md="4"
          xl="2"
        >
          <LegendItem
            range="1-4"
            percentage="80%"
            :rating="4"
          />
        </b-col>
        <b-col
          sm="4"
          md="4"
          xl="2"
        >
          <LegendItem
            range="5-6"
            percentage="60%"
            :rating="3"
          />
        </b-col>
        <b-col
          sm="4"
          md="4"
          xl="2"
        >
          <LegendItem
            range="7-10"
            percentage="40%"
            :rating="2"
          />
        </b-col>
        <b-col
          sm="4"
          md="4"
          xl="2"
        >
          <LegendItem
            range="11-15"
            percentage="20%"
            :rating="1"
          />
        </b-col>
        <b-col
          sm="4"
          md="4"
          xl="2"
        >
          <LegendItem
            range="15+"
            percentage="10%"
            :rating="0"
          />
        </b-col>
      </b-row>

      <b-row
        v-if="type === 'primary'"
        class="p-5 pb-0"
      >
        <b-col class="question">
          <b-card
            title=""
            header-tag="header"
          >
            <template
              #header
              class="blue-bg"
            >
              <b-container
                fluid
                class="m-0 p-0"
              >
                <b-row class="p-4 pb-0">
                  <b-col
                    cols="8"
                    class="d-flex justify-center align-items-center"
                  >
                    <h4 class="mb-0 text-white">WHAT IS YOUR RIGHT NUMBER ?</h4>
                  </b-col>
                  <b-col
                    cols="4"
                    class="text-right"
                  >
                    <h1 class="mb-0 text-white">PRIMARY</h1>
                  </b-col>
                </b-row>
              </b-container>
            </template>
            <b-row>

              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <transition name="fade">
                  <GradeItem title="Full Black" />
                </transition>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Full Sour" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Fungus Attack" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem
                  title="Foreign Matter"
                  :rating="2"
                />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Insect Damage" />
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col>
                <hr>
              </b-col>
            </b-row>
            <b-row>

              <b-col cols="4">

                <b-button
                  variant="primary"
                  class="btn-blue"
                  @click="type = 'primary'"
                > Calculate</b-button>
              </b-col>
              <b-col class="text-left">
                &nbsp;
              </b-col>
            </b-row>

          </b-card>
        </b-col>
      </b-row>

      <b-row
        v-if="type === 'secondary'"
        class="p-5 pb-0"
      >
        <b-col class="question">
          <b-card
            title=""
            header-tag="header"
          >
            <template
              #header
              class="blue-bg"
            >
              <b-container
                fluid
                class="m-0 p-0"
              >
                <b-row class="p-4 pb-0">
                  <b-col
                    cols="8"
                    class="d-flex justify-center align-items-center"
                  >
                    <h4 class="mb-0 text-white">WHAT IS YOUR RIGHT NUMBER ?</h4>
                  </b-col>
                  <b-col
                    cols="4"
                    class="text-right"
                  >
                    <h1 class="mb-0 text-white">SECONDARY</h1>
                  </b-col>
                </b-row>
              </b-container>
            </template>
            <b-row>

              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <transition name="fade">
                  <GradeItem title="Partial Black" />
                </transition>
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Partial Sour" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Floater" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Withered" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Shell" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Slightly Insect Damaged" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Foxy" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Under Dried" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Over Dried" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Mixed Dried" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Stinker" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Faded" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Coated" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Immature" />
              </b-col>
              <b-col
                sm="12"
                md="6"
                lg="3"
              >
                <GradeItem title="Starved" />
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-col>
                <hr>
              </b-col>
            </b-row>
            <b-row>

              <b-col cols="4">

                <b-button
                  variant="primary"
                  class="btn-blue"
                  @click="type = 'primary'"
                > Calculate</b-button>
              </b-col>
              <b-col class="text-left">
                &nbsp;
              </b-col>
            </b-row>

          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import GradeItem from '@/components/GradeItem.vue'
import LegendItem from '@/components/LegendItem.vue'

export default {
  name: "Home",
  components: {
    GradeItem,
    LegendItem
  },
  data: () =>{
    return {
      isStartGrading: false,
      type: 'none'
    }
  },
  computed: {

  }

}
</script>
