<template>
  <b-container
    fluid
    class="pl-4 pt-3 pb-4 pr-4 shadow-bg"
  >
    <b-row class="pt-0 mt-0 d-flex">
      <b-col
        lg="6"
        md="6"
        sm="6"
        class="text-left grade-title"
      ><strong>{{ title }}</strong></b-col>
      <b-col class="text-right">
        <b-icon
          icon="info-circle"
          class="grade-info"
          @click="imgShown = !imgShown"
        />
      </b-col>
    </b-row>
    <b-row
      v-if="imgShown"
      class="pt-3 mt-0 d-flex"
    >
      <b-col>
        <img
          :src="getImg(title)"
          style="width:100%"
        >
      </b-col></b-row>
    <b-row class="pt-3 mt-0 d-flex">
      <b-col class="text-left">
        <select
          v-model="defectValue"
          class="cst-dropdown"
          @change="onChange($event)"
        >
          <option
            v-for="(item, index) in options"
            :key="index"
          >{{ item }}</option>

        </select>
      </b-col>
    </b-row>
    <b-row
      class="pt-3 mt-0 d-flex"
    >
      <b-col class="text-left">
        <img
          v-for="(n,index) in computeRating(defectValue)"
          :key="index"
          src="../assets/star.svg"
          alt="star"
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'GradeItem',
  props: {
    title: {
      type: String,
      default: ''
    },
    img: {
      type: String,
      default: ''
    }
  },
  data: () => {
    const defectValue = 0;
    return {
      defectValue,
      options: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
      imgShown: false,
    }
  },
  methods: {
    computeRating: (defectValue)=> {
      return defectValue === 0 ? 5 :
       defectValue>=1 && defectValue<=4 ? 4
       : defectValue >= 5 && defectValue <= 6 ? 3
       : defectValue >= 7 && defectValue <= 10 ? 2
       : defectValue >= 11 && defectValue <= 15 ? 1 : 0;
    },
    onChange: (event) => {
      console.log(event.target.value);
    },
    getImg: (title) => {

      const available = ['Full Black','Full Sour','Fungus Attack','Insect Damage', 'Foreign Matter'];
      if (available.includes(title)) {
        // eslint-disable-next-line no-undef
        return require('../assets/'+title+'.png')
      }else {
        return "";
      }
    }
  }
}
</script>
